define("discourse/plugins/DiscourseMatheditor/discourse/controllers/matheditor-modal", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/object"], function (_exports, _controller, _decorators, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      insertEq() {
        this.toolbarEvent.addText('$$\n' + this.toolbarEvent.mathfield.$text("latex-expanded") + '\n$$');
        this.send("closeModal");
      }
    }
  });
});