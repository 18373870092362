define("discourse/plugins/DiscourseMatheditor/initializers/discourse-matheditor", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal", "discourse/lib/load-script", "discourse-common/lib/get-owner"], function (_exports, _pluginApi, _showModal, _loadScript, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDiscourseMatheditor(api) {
    (0, _loadScript.default)("/plugins/DiscourseMatheditor/mathlive/mathlive.js");

    //make sure the keyboard is turned off
    api.onAppEvent('modal:body-dismissed', function () {
      const controller = (0, _getOwner.getOwner)(this).lookup('controller:composer');
      if (!controller.toolbarEvent || !controller.toolbarEvent.mathfield) {
        return;
      }
      controller.toolbarEvent.mathfield.$perform("hideVirtualKeyboard");
    });
    api.modifyClass("controller:composer", {
      actions: {
        showMathEditor() {
          const modalcontroller = (0, _showModal.default)("matheditor-modal").set("toolbarEvent", this.toolbarEvent);
          Ember.run.later(this, function () {
            this.toolbarEvent.mathfield = MathLive.makeMathField('mathfield', {
              virtualKeyboardMode: "manual"
            });
          }, 50);
        }
      }
    });
    api.addToolbarPopupMenuOptionsCallback(() => {
      return {
        action: "showMathEditor",
        icon: "chart-bar",
        label: "discourse_matheditor.openeditor"
      };
    });
  }
  var _default = _exports.default = {
    name: "discourse-matheditor",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeDiscourseMatheditor);
    }
  };
});